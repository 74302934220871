import 'jquery';
(function ($) {
    $(document).ready(function () {
$('.main-header__more').click(() => {
    $('.main-header__nav').toggleClass('opened').toggle(100);
})
        $('.main-header__nav--item.has-children').click(function(){
            let activeItem = $('.main-header__nav--item.active');
           $(this).toggleClass('active');
           activeItem.removeClass('active')
        })

            const checkOffsetY = (y) => {
                if (y > 50) {
                    $('.main-header__navbar').addClass('scrolled');
                } else {
                    $('.main-header__navbar').removeClass('scrolled');
                }
            }
                $(document).scroll(function() {
                    checkOffsetY($(this).scrollTop())
                });
                checkOffsetY($(this).scrollTop())


    });
})(jQuery);