import Swiper, {Autoplay, Navigation, Pagination} from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

window.addEventListener("load",function () {
        const swiper = new Swiper('.swiper', {

            loop: true,
            autoplay: {
                delay: 3000,
            },
            watchSlidesProgress: true,
            slidesPerView: 1,
            spaceBetween: 18,

            breakpoints: {

                992: {
                    slidesPerView: 3,
                    spaceBetween: 33
                }
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                type: 'bullets',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    });


