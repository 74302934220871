
import 'bootstrap';
import './header';
import './brands';
import './testimonials';
import "../styles/main.scss";
(function ($) {
    const checkOffsetY = (y) => {
        if (y > 100) {
            $('.scrollTop').fadeIn(200);
        } else {
            $('.scrollTop').fadeOut(200);
        }
    }
    $(document).ready(function () {

        $(document).scroll(function() {
            checkOffsetY($(this).scrollTop())
        });
        checkOffsetY($(this).scrollTop())
        $('.scrollTop').click(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        })
    });
})(jQuery);
